import { Box, Button, Flex, Input, Select, SimpleGrid, Text, Textarea } from '@chakra-ui/react';
import Card from 'components/card/Card';

export default function StandsPage() {
    // Chakra Color Mode
    const itemFontSize = { sm: '1.1em' }
    const inputItemWidth = '50%'
    const itemRowBgColor = '#F4F7FE'


    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
                <Card flexDirection='column' w='100%' px='20px' my='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <SimpleGrid columns={{ base: 1, md: 4 }} gap='20px' py='15px' px='10px' justifyContent='space-between'>
                        <Flex alignItems='center'>
                            <Text
                                fontSize={itemFontSize}
                                fontWeight='400'
                                color='black.600'>
                                Site
                            </Text>
                            <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                                <option value='yes'>Damonsville</option>
                                <option value='no'>Lephalale</option>
                                <option value='n_a'>Segwaelane</option>
                            </Select>
                        </Flex>
                        <Flex alignItems='center'>
                            <Text
                                fontSize={itemFontSize}
                                fontWeight='400'
                                color='black.600'>
                                Team member
                            </Text>
                            <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                                <option value='yes'>John</option>
                                <option value='no'>Peter</option>
                                <option value='n_a'>Andrew</option>
                            </Select>
                        </Flex>
                        <Flex alignItems='center'>
                            <Text
                                fontSize={itemFontSize}
                                fontWeight='400'
                                color='black.600'>
                                Due date
                            </Text>
                            <Input placeholder='Enter date' width={inputItemWidth} ml='20px'></Input>
                        </Flex>
                        <Flex alignItems='center' justifyContent='end'>
                            <Text
                                fontSize={itemFontSize}
                                fontWeight='400'
                                color='black.600'>
                                Supervisor
                            </Text>
                            <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                                <option value='yes'>Tlotlo</option>
                                <option value='no'>Jonathan</option>
                                <option value='n_a'>Jaemes</option>
                            </Select>
                        </Flex>
                    </SimpleGrid>
                </Card>

                <Card flexDirection='column' w='100%' px='20px' my='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <Text
                        fontSize={{ sm: '20px', lg: '25px' }}
                        fontWeight='700'
                        color='brand.600'
                        mb='20px'>
                        Enter the stand numbers below, separated by comma or one stand number per line                            </Text>
                    <Textarea placeholder='100,101,102,103,104' />
                    <Flex justifyContent='end'>
                        <Button width='30%' backgroundColor='brand.600' color='white' size='md' my='20px'>Submit</Button>
                    </Flex>
                </Card>
            </SimpleGrid>
        </Box>
    );
}
