// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdBarChart, MdLocationPin, MdOutlineList, MdPerson } from 'react-icons/md';
import DailyTraffic from 'views/admin/default/components/DailyTraffic';

export default function UserReports() {
	// Chakra Color Mode
	const brandColor = useColorModeValue('brand.500', 'white');
	const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
	return (
		<Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
			<SimpleGrid columns={{ base: 1, md: 4, lg: 4, '2xl': 4 }} gap='20px' mb='20px'>
				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
						/>
					}
					name='Overall Progress'
					value='1200 / 5000'
				/>

				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdLocationPin} color={brandColor} />}
						/>
					}
					name='Active Sites'
					value='6'
				/>

				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdOutlineList} color={brandColor} />}
						/>
					}
					name='Stands Allocated'
					value='2000 / 5000'
				/>

				<MiniStatistics
					startContent={
						<IconBox
							w='56px'
							h='56px'
							bg={boxBg}
							icon={<Icon w='32px' h='32px' as={MdPerson} color={brandColor} />}
						/>
					}
					name='Team Members'
					value='5'
				/>
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px' mb='50px'>
				<DailyTraffic increase='10' title='Oukasie' />
				<DailyTraffic increase='50' title='Segwaelane' />
				<DailyTraffic increase='90' title='Damonsville' />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 3, xl: 3 }} gap='20px'>
				<DailyTraffic increase='0' title='Tokyo' />
				<DailyTraffic increase='5' title='Lephalale' />
				<DailyTraffic increase='150' title='Soshanguve' />
			</SimpleGrid>
		</Box>
	);
}
