// Chakra imports
import { Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets

// Custom components
import Card from 'components/card/Card';
import { NavLink } from 'react-router-dom';

export default function MobileStands() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid columns={{ base: 1 }} gap='20px'>
                <NavLink to='/admin/stand-overview'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        justifyContent='center'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>100</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-overview'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        justifyContent='center'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>101</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-overview'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        justifyContent='center'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>102</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-overview'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        justifyContent='center'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>103</Text>
                    </Card>
                </NavLink>


                <NavLink to='/admin/stand-overview'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        justifyContent='center'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>104</Text>
                    </Card>
                </NavLink>
            </SimpleGrid>
        </Box>
    );
}
