// Chakra imports
import { Box, Button, Flex, Input, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import { NavLink } from 'react-router-dom';

export default function StandDescription() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const itemFontSize = { sm: '1.1em' }
    const inputItemWidth = '100%'
    const itemRowBgColor = '#F4F7FE'

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

            <SimpleGrid columns={{ base: 1 }} gap='20px' my='10px' justifyItems='center'>
                <Text
                    fontSize={{ sm: '20px', lg: '25px' }}
                    fontWeight='700'
                    color='brand.600'>
                    Description
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap='20px' py='15px' px='10px' justifyContent='space-between'>
                <Card flexDirection='column' w='100%' px='20px' my='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            GPS Co-ords
                        </Text>
                        <Input placeholder={`0°0'0"`} width={inputItemWidth} ml='20px'></Input>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Construction Stage
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Vacant Land</option>
                            <option value='no'>Foundation Level</option>
                            <option value='n_a'>Wall Plate</option>
                            <option value='n_a'>Roof level</option>
                            <option value='n_a'>Completed</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Occupied
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Alterations
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Access
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Trees within 4m
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 2 }} gap='20px' my='10px' justifyItems='center' alignItems='center'>
                <NavLink to='/admin/stand-overview'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Overview</Button>
                </NavLink>
                <NavLink to='/admin/stand-platform'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Next</Button>
                </NavLink>
            </SimpleGrid>
        </Box>
    )
}