// Chakra imports
import { Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets

// Custom components
import Card from 'components/card/Card';
import { NavLink } from "react-router-dom";

export default function MobileStandOverview() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }} width='100%'>
            <SimpleGrid columns={{ base: 2 }} gap='10px' width='100%' my='40px' justifyContent='space-around'>
                <Text fontSize='xl' fontWeight='bold'>Segwaelane</Text>
                <Text fontSize='xl' fontWeight='bold'>Progress: 20%</Text>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap='20px' width='100%'>
                <NavLink to='/admin/stand-description'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>Description</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-platform'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>Platform</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-roof'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>Roof</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/stand-wall'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text fontSize='md' fontWeight='bold'>Wall</Text>
                    </Card>
                </NavLink>
            </SimpleGrid>
        </Box>
    );
}
