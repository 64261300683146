// Chakra imports
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Text
				justifyContent='space-around'
				align='left'
				fontSize={{ sm: '24px', lg: '30px' }}
				fontWeight='bold'
				color='brand.500'
				my='50px'
				ml='-50px'>
				Site Forensics
			</Text>
			<HSeparator mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
