import { Icon } from '@chakra-ui/react';
import { MdHome, MdLocationPin, MdLock, MdOutlineList, MdOutlineListAlt, MdPerson } from 'react-icons/md';

// Admin Imports
import AllocatePage from 'views/admin/allocation';
import DataTables from 'views/admin/dataTables';
import MainDashboard from 'views/admin/default';
import MobileHome from 'views/admin/mobile/home';
import TeamTable from 'views/admin/profile';
import StandsTable from 'views/admin/stands';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
	{
		name: 'Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Sites',
		layout: '/admin',
		icon: <Icon as={MdLocationPin} width='20px' height='20px' color='inherit' />,
		path: '/sites',
		component: DataTables
	},
	{
		name: 'Stands',
		layout: '/admin',
		icon: <Icon as={MdOutlineList} width='20px' height='20px' color='inherit' />,
		path: '/stands',
		component: StandsTable
	},
	{
		name: 'Team Members',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: TeamTable
	},
	{
		name: 'Allocations',
		layout: '/admin',
		path: '/allocate',
		icon: <Icon as={MdOutlineListAlt} width='20px' height='20px' color='inherit' />,
		component: AllocatePage
	},
	{
		name: 'Mobile',
		layout: '/admin',
		path: '/mobile',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MobileHome
	},
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/sign-in',
		icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
		component: SignInCentered
	}
];

export const sideBarRoutes = [
	{
		name: 'Dashboard',
		layout: '/admin',
		path: '/default',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Sites',
		layout: '/admin',
		icon: <Icon as={MdLocationPin} width='20px' height='20px' color='inherit' />,
		path: '/sites',
		component: DataTables
	},
	{
		name: 'Stands',
		layout: '/admin',
		icon: <Icon as={MdOutlineList} width='20px' height='20px' color='inherit' />,
		path: '/stands',
		component: StandsTable
	},
	{
		name: 'Team Members',
		layout: '/admin',
		path: '/profile',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
		component: TeamTable
	},
];

export default routes;
