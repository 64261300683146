// Chakra imports
import { Box, Button, Flex, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import { NavLink } from 'react-router-dom';

export default function StandRoof() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const itemFontSize = { sm: '1.1em' }
    const inputItemWidth = '100%'
    const itemRowBgColor = '#F4F7FE'

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

            <SimpleGrid columns={{ base: 1 }} gap='20px' my='10px' justifyItems='center'>
                <Text
                    fontSize={{ sm: '20px', lg: '25px' }}
                    fontWeight='700'
                    color='brand.600'>
                    Roof
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap='20px' py='15px' px='10px' justifyContent='space-between'>
                <Card flexDirection='column' w='100%' px='20px' my='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof present
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Ceiling present
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof anchor present
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof anchors adequacy
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Adequate</option>
                            <option value='no'>Inadequate</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof sheet damaged
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select></Flex>
                </Card>

                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Rust on roof sheet
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof leaking
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Loose sheets
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Roof has defects
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Beam filling present
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Beam filling quality
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Poor</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 2 }} gap='20px' my='10px' justifyItems='center' alignItems='center'>
                <NavLink to='/admin/stand-description'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Previous</Button>
                </NavLink>

                <NavLink to='/admin/stand-wall'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Next</Button>
                </NavLink>

            </SimpleGrid>
        </Box>
    )
}