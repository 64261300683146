// Chakra imports
import { Box, Button, Flex, Input, Select, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import Card from 'components/card/Card';
import { NavLink } from 'react-router-dom';

export default function StandWall() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

    const itemFontSize = { sm: '1.1em' }
    const inputItemWidth = '100%'
    const itemRowBgColor = '#F4F7FE'

    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

            <SimpleGrid columns={{ base: 1 }} gap='20px' my='10px' justifyItems='center'>
                <Text
                    fontSize={{ sm: '20px', lg: '25px' }}
                    fontWeight='700'
                    color='brand.600'>
                    Wall
                </Text>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 1 }} gap='20px' py='15px' px='10px' justifyContent='space-between'>
                <Card flexDirection='column' w='100%' px='20px' my='20px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Walls plastered inside
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Walls plastered outside
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Plaster condition
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Poor</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Plumb vertical
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Aligned</option>
                            <option value='no'>Tilt</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Damage to wall
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>

                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Wall has defects
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Window frames
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Door frames
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Poor</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Frames condition
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Damaged / Loose</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>

                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Mortar joints quality
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Poor</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Mortar condition
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Good</option>
                            <option value='no'>Poor</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Mortar thickness
                        </Text>
                        <Input placeholder='in millimeters' width={inputItemWidth} ml='20px'></Input>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Partition walls stable
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Brick force present
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
                <Card>
                    <Flex alignItems='center' justifyContent='end'>
                        <Text
                            fontSize={itemFontSize}
                            fontWeight='400'
                            color='black.600'>
                            Consistence of brick force
                        </Text>
                        <Select placeholder='Select option' width={inputItemWidth} ml='20px'>
                            <option value='yes'>Yes</option>
                            <option value='no'>No</option>
                            <option value='n_a'>N/A</option>
                        </Select>
                    </Flex>
                </Card>
            </SimpleGrid>
            <SimpleGrid columns={{ base: 2 }} gap='20px' my='10px' justifyItems='center' alignItems='center'>
                <NavLink to='/admin/stand-roof'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Previous</Button>
                </NavLink>
                <NavLink to='/admin/stand-overview'>
                    <Button width='100%' backgroundColor='brand.600' color='white' size='md' my='10px'>Complete</Button>
                </NavLink>

            </SimpleGrid>
        </Box>
    )
}