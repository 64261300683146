import { Box, SimpleGrid } from '@chakra-ui/react';
import { standDataComplex } from 'views/admin/dataTables/variables/tableDataDevelopment';
import StandsTable from '../dataTables/components/StandsTable';

export default function StandsPage() {
    // Chakra Color Mode
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid mb='20px' columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>
                <StandsTable tableData={standDataComplex} />
            </SimpleGrid>
        </Box>
    );
}
