type RowObj = {
	site: string;
	status: string;
	date: string;
	progress: string;
	supervisor: string;
	active: string;
};

type TeamRowObj = {
	name: string;
	contact: string;
	progress: string;
	supervisor: string;
};

type StandRowObj = {
	standNumber: string;
	site: string;
	progress: string;
	supervisor: string;
	teamMember: string;
};

const tableDataComplex: RowObj[] = [
	{
		site: 'Damonsville',
		status: 'In Progress',
		date: '12.Jan.2021',
		progress: '75/100',
		supervisor: 'Prof Wekesa',
		active: 'no',
	},
	{
		site: 'Oukasie',
		status: 'Completed',
		date: '21.Feb.2021',
		progress: '200/200',
		supervisor: 'Tlotlo',
		active: 'yes',
	},
	{
		site: 'Tokyo',
		status: 'Not Started',
		date: '13.Mar.2021',
		progress: '0/75',
		supervisor: 'Prof Wekesa',
		active: 'yes',
	},
	{
		site: 'Segwaelane',
		status: 'In Progress',
		date: '24.Jan.2021',
		progress: '100/200',
		supervisor: 'Francis',
		active: 'yes',
	},
	{
		site: 'Lephalale',
		status: 'In Progress',
		date: 'Oct 24, 2022',
		progress: '750/1000',
		supervisor: 'Prof Wekesa',
		active: 'no',
	},
	{
		site: 'Damonsville 2',
		status: 'Not Started',
		date: 'Oct 24, 2022',
		progress: '0/300',
		supervisor: 'Prof Wekesa',
		active: 'no',
	},
	{
		site: 'Segwaelane',
		status: 'Completed',
		date: '12.Jan.2021',
		progress: '750/750',
		supervisor: 'John',
		active: 'yes',
	},
	{
		site: 'Oukasie',
		status: 'In Progress',
		date: '21.Feb.2021',
		progress: '90/100',
		supervisor: 'John',
		active: 'yes',
	},

];

const teamDataComplex: TeamRowObj[] = [
	{
		name: 'John',
		contact: '012 345 6789',
		progress: '75/100',
		supervisor: 'Prof Wekesa'
	},
	{
		name: 'Peter',
		contact: '012 345 6789',
		progress: '200/200',
		supervisor: 'Tlotlo'
	},
	{
		name: 'Andrew',
		contact: '012 345 6789',
		progress: '0/75',
		supervisor: 'Prof Wekesa'
	},
	{
		name: 'Collins',
		contact: '012 345 6789',
		progress: '100/200',
		supervisor: 'Francis'
	},
	{
		name: 'George',
		contact: '012 345 6789',
		progress: '750/1000',
		supervisor: 'Prof Wekesa'
	},
	{
		name: 'Henry',
		contact: '012 345 6789',
		progress: '0/300',
		supervisor: 'Prof Wekesa'
	}
];

const standDataComplex: StandRowObj[] = [
	{
		standNumber: '100',
		site: 'Segwaelane',
		progress: '65%',
		supervisor: 'Prof Wekesa',
		teamMember: 'John',
	},
	{
		standNumber: '303',
		site: 'Damonsville',
		progress: '15%',
		supervisor: 'Tlotlo',
		teamMember: 'Collins',
	},
	{
		standNumber: '322',
		site: 'Lephalale',
		progress: '2%',
		supervisor: 'Prof Wekesa',
		teamMember: 'Andrew',
	},
	{
		standNumber: '434',
		site: 'Damonsville',
		progress: '90%',
		supervisor: 'Francis',
		teamMember: 'George',
	},
	{
		standNumber: '222',
		site: 'Tokyo',
		progress: '80%',
		supervisor: 'Prof Wekesa',
		teamMember: 'Henry',
	},
	{
		standNumber: '423',
		site: 'Lephalale',
		progress: '40%',
		supervisor: 'Prof Wekesa',
		teamMember: 'George',
	}
];

export { standDataComplex, tableDataComplex, teamDataComplex };

