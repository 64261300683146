// Chakra imports
import { Box, SimpleGrid, Text, useColorModeValue } from '@chakra-ui/react';
// Assets

// Custom components
import Card from 'components/card/Card';
import { NavLink } from "react-router-dom";

export default function MobileSites() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }} width='100%'>
            <SimpleGrid columns={{ base: 1 }} gap='20px' width='100%'>
                <NavLink to='/admin/my-stands'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text>Segwaelane</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/my-stands'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text>Damonsville</Text>
                    </Card>
                </NavLink>
                <NavLink to='/admin/my-stands'>
                    <Card
                        alignItems='center'
                        flexDirection='row'
                        w='100%'
                        minW='100%'
                        p='20px 15px'
                        h='max-content'
                        marginBottom='20px'>
                        <Text>Lephalale</Text>
                    </Card>
                </NavLink>

            </SimpleGrid>
        </Box>
    );
}
