// Chakra imports
import { Box, Icon, SimpleGrid, useColorModeValue } from '@chakra-ui/react';
// Assets
// Custom components
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { MdBarChart, MdLocationPin, MdOutlineList } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

export default function MobileHome() {
    // Chakra Color Mode
    const brandColor = useColorModeValue('brand.500', 'white');
    const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    return (
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
            <SimpleGrid columns={{ base: 1 }} gap='20px' mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={<Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />}
                        />
                    }
                    name='Overall Progress'
                    value='100 / 500'
                />

                <NavLink to='/admin/my-sites'>
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={<Icon w='32px' h='32px' as={MdLocationPin} color={brandColor} />}
                            />
                        }
                        name='Sites'
                        value='3'
                    />
                </NavLink>

                <NavLink to='/admin/my-sites'>
                    <MiniStatistics
                        startContent={
                            <IconBox
                                w='56px'
                                h='56px'
                                bg={boxBg}
                                icon={<Icon w='32px' h='32px' as={MdOutlineList} color={brandColor} />}
                            />
                        }
                        name='Stands'
                        value='500'
                    />
                </NavLink>

            </SimpleGrid>
        </Box>
    );
}
